  @import 'simOrder';
.landing-page {
  @import 'slickCarousel';
  @import 'touristsToVietnam';
  @import 'travelAbroad';
  @import 'landingPage';
  @import "aboutEsim";

  .main-banner {
    width: 100%;
  }

  @media (max-width: 1800px) {
    .list-contact {
      right: 40px;
    }
  }

  @media (max-width: 1500px) {
    .list-contact {
      right: 10px;
      z-index: 1000;
    }
  }

  @media (max-width: 1400px) {
    .banner h1 {
      font-size: 30px;
      line-height: 1.5;
      width: 38%;
      // padding-top: 70px;
      margin-bottom: 20px;
    }

    .sub-slogan {
      font-size: 14px;
      width: 35%;
    }

    .btn-order-now {
      font-size: 16px;
      line-height: 1.5;
    }

    .call-head {
      font-size: 24px;
      line-height: 1.5;
    }
  }

  @media (max-width: 1200px) {
    .banner {
      padding-left: 8%;

      h1 {
        padding-top: 7%;
      }
    }

    .footer-top {
      flex-wrap: wrap;
    }

    .footer-list {
      flex-wrap: wrap;
    }

    .footer-right {
      margin-top: 20px;
    }
  }

  @media (max-width: 650px) {
    .list-contact {
      display: none;
    }

    .slick-carousel .carousel-item {

      .content-section,
      .image-section {
        margin-left: 70px;
        margin-right: 70px;
        border: none;
        padding: 0;
      }
    }

    .footer-list {
      flex-wrap: wrap;
      flex-direction: column;
    }

    .footer-right {
      margin-top: 20px;
      width: 100%;
    }

    .footer-header {
      margin-top: 20px;
    }

    .footer-top-cont {
      padding-top: 0;
    }

    .banner {
      background-image: none;
      min-height: auto;
      padding-left: 0;

      h1 {
        width: 100%;
      }
    }

    .sub-slogan {
      width: 100%;
    }

    .info-call-list {
      font-size: 14px;
    }

    .sim-divider {
      width: 70%;
    }

    // .logo-img {
    //   position: unset;
    // }

    .dropdown-lang {
      right: 0;
      left: unset;
    }

    .sim-order {
      .input-block {
        .item-section {
          .ant-space {
            flex-wrap: wrap;
            gap: 0px !important;
            padding-top: 20px;
            border-top: 2px solid #1992bc;

            .ant-space-item:nth-child(2) {
              margin-right: 0px;
              margin-right: 15px;
              width: calc(100% - 46px) !important;
            }
          }

          .ant-space:first-child {
            padding-top: 0px;
            border-top: none;

            // .ant-space-item:nth-child(2) {
            //   margin-right: 0px;
            //   width: 100% !important;
            // }
          }
        }
      }
    }

    .main-banner {
      position: relative;
      // background-image: url(/images/common/bg_mb.png);
      padding-bottom: 60px;
      // background-size: cover;
      // background-repeat: no-repeat;
    }

    .img-mb-banner {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .main-banner-cont {
      padding: 0 25px;
    }

    .button-select-lang {
      border: none;
    }

    .selected-lang {
      display: none;
    }
  }

  .button-arrow {
    position: absolute;
    top: 50%;
    border: none;
    background-color: transparent;
    z-index: 999;
  }

  .button-arrow-left {
    left: -30px;
  }

  .button-arrow-right {
    right: -30px;
  }

  // @media (max-width: 800px) {
  //     .banner {
  //       padding-left: 8%;
  //       h1 {
  //         padding-top: 7%;
  //       }
  //     }
  //     .footer-top {
  //       flex-wrap: wrap;
  //     }
  //   }
}


@media (max-width: 650px) {
  .header-landing {
    height: unset;
  }

  .button-lang-cont {
    border: none;
  }
}
