.tourists-to-vietnam {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 30px;
    font-weight: 700;
    color: #006e99;
    margin-top: 10px;
    text-align: center;
  }
  .content {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0 30px;
  }
}

.tab-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #c6c6c6;
  cursor: pointer;
  .tab-item-text {
    padding: 7px 0;
    display: flex;
    justify-content: center;
    width: 150px;
  }
  .tab-divider {
    background: #dbdbdb;
    border-radius: 70px;
    width: 100%;
    height: 1.66px;
  }
  &.active {
    font-weight: 700;
    color: #006e99;
    .tab-divider {
      background: #006e99;
    }
  }
}

.tab-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  width: 100%;
  overflow: auto;
}